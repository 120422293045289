import { h } from 'preact';
export const ExternalLinkIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M11.875 11.875H3.125V3.125H6.875V1.875H3.125C2.43125 1.875 1.875 2.4375 1.875 3.125V11.875C1.875 12.5625 2.43125 13.125 3.125 13.125H11.875C12.5625 13.125 13.125 12.5625 13.125 11.875V8.125H11.875V11.875ZM8.75 1.875V3.125H10.9938L4.85 9.26875L5.73125 10.15L11.875 4.00625V6.25H13.125V1.875H8.75Z" fill="currentColor" />
</g>
</svg>
  )
}
