import { h } from 'preact';
import register from 'preact-custom-element';
import { useState } from 'preact/hooks';
import { FocusTrap } from './focus-trap-preact';
import GridIcon from './gridIcon';
import { Modal } from './modal';
import { SwitcherCloud } from './switcherCloud';
import { SwitcherFlow } from './switcherFlow';
import { SwitcherSkills } from './switcherSkills';
import Cookies from 'js-cookie'
import CheckmarkIcon from './checkmarkIcon';

const outboundLinkDict = {
  prod: 'https://out.acloud.guru',
  staging: 'https://staging-out.acloud.guru'
}

const options = [{
  id: 'skills',
  title: 'Skills',
  svgComponent: SwitcherSkills,
  rightComponent: null,
  stagingUrl: 'https://app-stage.pluralsight.com/id/signin/sso?Alias=acg-test',
  url: 'https://app.pluralsight.com/id/signin/sso?Alias=Acg-oidc'
}, {
  id: 'cloud',
  title: 'A Cloud Guru',
  svgComponent: SwitcherCloud,
  rightComponent: CheckmarkIcon,
  current: true,
  stagingUrl: 'https://staging-learn.acloud.guru',
  url: 'https://learn.acloud.guru'
}, {
  id: 'flow',
  title: 'Flow',
  svgComponent: SwitcherFlow,
  rightComponent: null,
  stagingUrl: 'https://app-stage.pluralsight.com/id/signin/sso?Alias=acg-test&RedirectTo=https%3A%2F%2Fflow-staging.pluralsight.com',
  url: 'https://app.pluralsight.com/id/signin/sso?Alias=Acg-oidc&RedirectTo=https%3A%2F%2Fflow.pluralsight.com'
}]

const Switcher = ({ blocked = false, email, personal, business }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [dropdownClass, setDropdownClass] = useState('switcherDropdown')
  const [showModal, setShowModal] = useState(false)
  const [targetSite, setTargetSite] = useState(null)
  const isBlocked = blocked === "true";
  const isStaging = document.location.host.indexOf('staging-') === 0 || document.location.host.indexOf('test-auto') === 0;

  const sendCustomeEvent = (action, data) => {
    // Emit the custom event
    const event = new CustomEvent('wcAppSwitcherEvent', { detail: { action, data } });
    window.dispatchEvent(event);
  }

  const handleKeyPress = e => {
    // Trigger click handler when Enter or Space pressed
    // Use preventDefault to prevent Space from scrolling page
    if (['Enter', ' '].includes(e.key)) {
      e.target.click();
      e.preventDefault();
    }
  };

  const handleTriggerClick = () => {
    if (isOpen) {
      hideDropdown();
      setIsOpen(false);
    } else {
      showDropdown();
      setIsOpen(true);
    }
  }

  const showDropdown = () => {
    setDropdownClass('switcherDropdown switcherDropdownActive')
    sendCustomeEvent('AppSwitcher Clicked');
  }

  const hideDropdown = () => {
    setDropdownClass('switcherDropdown')
  }

  const handleChoiceClicked = (e, choice) => {
    const target = choice.title;

    sendCustomeEvent('AppSwitcher Choice Clicked', { target });

    if (isBlocked && !choice.current) {
      setTargetSite(target);
      // setIsOpen(false);
      setShowModal(true);
      e.preventDefault();
      return false;
    }
  }

  const onClose = () => {
    setShowModal(false);
  }

  return (
    <div class="switcher">
      <style dangerouslySetInnerHTML={{
        __html: `
        .switcher {
          position: relative;
        }
        .switcherTrigger {
          cursor: pointer;
        }
        .switcherTrigger svg {
          color: #8E93C2;
        }
        .switcherDropdown {
          display: none;
          position: absolute;
          top: calc(100% + 8px);
          right: 0;
        }
        .switcherDropdown > span {
          width: 0; 
          height: 0; 
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid white;
          position: absolute;
          top: -6px;
          right: 6px;
        }
        .switcherDropdownActive { 
          display: block;
        }
        .switcherDropdownBox {
          border-radius: 4px;
          background: white;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .switcherDropdownBox--Inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          padding: 4px 0;
        }
        .switcherDropdown a {
          width: 200px;
          padding: 4px 8px;
          transition: background .2s ease-in-out;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .switcherDropdown a:hover {
          background: #F7F7F7;
        }
        .switcherDropdown a.current svg:last-child {
          color: var(--ps-action-navigation);
          width: 20px;
          flex-grow: 0;
        }
        
        .modal {
          position: fixed;
          z-index: 9999;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        
        .modalBackdrop {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
            background: rgba(0,0,0,0.5);
        }
        .modal h1,
        .modal h2 {
          font-weight: 700;
          font-family: "GT Walsheim", "GT Walsheim Pro", sans-serif;
          letter-spacing: -.01em;
        }
        .modalContainer {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modalContent {
          position: relative;
          max-width: 100%;
          width: 550px;
          background: white;
          border-radius: 4px;
          padding: 24px;
          color: #333;
          max-height: 100vh;
          overflow: auto;
        }
        .modalContent--Header {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 0px;
          gap: 12px;
          margin-bottom: 24px;
        }
        .modalContent--Header h1 {
          font-size: 24px;
          margin: 0;
          color: #03001E;
          padding: 0;
          line-height: 125%;
        }
        .modalContent--Header p {
          width: 100%;
          max-width: 500px;
          margin: 0;
          color: #243252;
          line-height: 150%;
        }
        .modalContent--Panel {
          background: #F3F5FA;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
        }
        .modalContent p {
          font-size: 16px;
          font-weight: 400;
        }
        .modalContent--Panel h2 {
          font-size: 20px;
          color: #03001E;
          margin: 0 0 8px 0;
        }
        .modalContent--Panel p {
          font-size: 14px;
          line-height: 150%;
          color: #243252;
          margin: 0 0 8px 0;
        }
        .modalContent--Button__primary {
          display: inline-flex;
          border-radius: 3px;
          padding: 8px 12px;
          text-decoration: none;
          font-size: 14px;
          font-weight: 600;
          align-items: center;
          transition: background .2s ease-in-out, color .2s ease-in-out;
          gap: 8px;
          background: #427EFF;
          color: #FFF;
        }
        .modalContent--Button__primary:active,
        .modalContent--Button__primary:hover,
        .modalContent--Button__primary:focus-visible {
          background: #3068E1;
        }
        .modalContent--Button__secondary {
          background: #FFF;
          color: #243252;
        }
        .modalContent--Button__secondary:active,
        .modalContent--Button__secondary:hover,
        .modalContent--Button__secondary:focus-visible {
          background: #F9FBFD;
        }
        .modalContent--Close {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 16px;
          right: 16px;
          cursor: pointer;
        }
        .modalContent--Close:focus-visible,
        .modalContent--Column a:focus-visible {
          outline-color: #427EFF;
          outline-offset: 2px;
        }

        .modalContent--Option {
          padding: 4px 6px;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.02em;
          display: inline-block;
          margin: 0 0 4px 0;
        }
        .modalContent--Option__one {
          background: #0B623F;
          color: #E0FBF0;
          border-radius: 4px;
        }
        .modalContent--Option__two {
          padding: 4px 0;
          color: #6478A2;
        }
      `}} />
      <div class="switcherTrigger" tabIndex="0" onClick={handleTriggerClick} onKeyPress={handleKeyPress}>
        <GridIcon />
      </div>
      <div id="blocklist-modal">
        {showModal && <Modal site={targetSite} email={email} personal={personal} business={business} onClose={onClose} />}
      </div>
      {isOpen && <div class={dropdownClass}>
        <span />
        <div class="switcherDropdownBox" id="switcher-options">
          <FocusTrap
            focusTrapOptions={{
              onDeactivate: handleTriggerClick,
              clickOutsideDeactivates: true,
              returnFocusOnDeactivate: true,
            }}>
            <div class="switcherDropdownBox--Inner">
              {options.map(choice => (
                <Choice key={choice.id} isBlocked={isBlocked} isStaging={isStaging} {...choice} onClick={e => {
                  handleChoiceClicked(e, choice)
                }} />
              ))}
            </div>
          </FocusTrap>
        </div>
      </div>
      }
    </div>
  );
};


const Choice = ({ isBlocked, isStaging, ...c }) => {
  const { id, current, title, stagingUrl, url, onClick } = c;
  const envUrl = isStaging ? stagingUrl : url;
  const outboundLink = isStaging ? outboundLinkDict['staging'] : outboundLinkDict['prod'];
  const showInterstial = id === 'skills' && !Cookies.get('dontshowoutbound');
  const href = showInterstial ? outboundLink : envUrl;

  return (
    <a key={id} href={isBlocked && !current ? "#" : href} class={current ? "current" : ''} aria-label={title} tabIndex="0" onClick={onClick}>
      <c.svgComponent />
      {current && <c.rightComponent />}
    </a>
  )
};

register(Switcher, 'wc-app-switcher', ['blocked', 'email', 'personal', 'business'], { shadow: true });

export default Switcher;
