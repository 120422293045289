import { h } from 'preact';

export const SwitcherSkills = () => {
  return (
    <svg width="85" height="24" viewBox="0 0 85 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0V24H24V0H0ZM7.5 9.55781L11.7621 12L7.5 14.4653V9.55781ZM7.5 18.6377V16.2351L14.8342 12L7.5 7.7649V5.37874L18.9677 12L7.5 18.6377Z" fill="url(#paint0_linear_1_100)" />
<path d="M36.69 17.264C39.098 17.264 40.736 16.06 40.736 13.988V13.96C40.736 12.07 39.336 11.314 37.054 10.81C35.556 10.446 35.192 10.25 35.192 9.73199V9.70399C35.192 9.31199 35.556 9.00399 36.27 9.00399C37.166 9.00399 38.16 9.35399 39.084 10.012L40.442 8.07999C39.35 7.21199 38.006 6.73599 36.34 6.73599C33.96 6.73599 32.462 8.07999 32.462 9.95599V9.98399C32.462 12.07 34.058 12.672 36.214 13.162C37.67 13.512 38.006 13.75 38.006 14.212V14.24C38.006 14.716 37.572 14.996 36.774 14.996C35.598 14.996 34.52 14.562 33.526 13.778L32 15.598C33.246 16.704 34.926 17.264 36.69 17.264Z" fill="black" fill-opacity="0.9" />
<path d="M42.4663 17.096H45.1683V14.352L46.2323 13.204L48.9203 17.096H52.1683L48.1083 11.272L52.0283 6.90399H48.7943L45.1683 11.104V6.90399H42.4663V17.096Z" fill="black" fill-opacity="0.9" />
<path d="M53.4087 17.096H56.1247V6.90399H53.4087V17.096Z" fill="black" fill-opacity="0.9" />
<path d="M58.3733 17.096H66.0453V14.702H61.0753V6.90399H58.3733V17.096Z" fill="black" fill-opacity="0.9" />
<path d="M67.6187 17.096H75.2907V14.702H70.3207V6.90399H67.6187V17.096Z" fill="black" fill-opacity="0.9" />
<path d="M80.7982 17.264C83.2062 17.264 84.8442 16.06 84.8442 13.988V13.96C84.8442 12.07 83.4442 11.314 81.1622 10.81C79.6642 10.446 79.3002 10.25 79.3002 9.73199V9.70399C79.3002 9.31199 79.6642 9.00399 80.3782 9.00399C81.2742 9.00399 82.2682 9.35399 83.1922 10.012L84.5502 8.07999C83.4582 7.21199 82.1142 6.73599 80.4482 6.73599C78.0682 6.73599 76.5702 8.07999 76.5702 9.95599V9.98399C76.5702 12.07 78.1662 12.672 80.3222 13.162C81.7782 13.512 82.1142 13.75 82.1142 14.212V14.24C82.1142 14.716 81.6802 14.996 80.8822 14.996C79.7062 14.996 78.6282 14.562 77.6342 13.778L76.1082 15.598C77.3542 16.704 79.0342 17.264 80.7982 17.264Z" fill="black" fill-opacity="0.9" />
<defs>
<linearGradient id="paint0_linear_1_100" x1="34.2283" y1="35.6045" x2="-24.1827" y2="-26.4403" gradientUnits="userSpaceOnUse">
<stop offset="0.03" stop-color="#F05A28" />
<stop offset="0.93" stop-color="#EB008B" />
</linearGradient>
</defs>
</svg>

  )
}
