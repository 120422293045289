import { h } from 'preact';
    export const SwitcherFlow = () => {
      return (
        <svg width="78" height="24" viewBox="0 0 78 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M24 0H0V24H24V19.5H15V17.25H24V15H18.75V12.75H24V6H15V3.75H24V0ZM9.75 8.25H19.5V10.5H9.75V8.25ZM7.5 15V12.75H17.25V15H7.5ZM8.25 9.375C8.25 9.99632 7.74632 10.5 7.125 10.5C6.50368 10.5 6 9.99632 6 9.375C6 8.75368 6.50368 8.25 7.125 8.25C7.74632 8.25 8.25 8.75368 8.25 9.375ZM12.375 6C12.9963 6 13.5 5.49632 13.5 4.875C13.5 4.25368 12.9963 3.75 12.375 3.75C11.7537 3.75 11.25 4.25368 11.25 4.875C11.25 5.49632 11.7537 6 12.375 6ZM13.5 18.375C13.5 18.9963 12.9963 19.5 12.375 19.5C11.7537 19.5 11.25 18.9963 11.25 18.375C11.25 17.7537 11.7537 17.25 12.375 17.25C12.9963 17.25 13.5 17.7537 13.5 18.375Z" fill="url(#paint0_linear_1_101)" />
<path d="M32 17.096H34.702V13.4H39.532V11.118H34.702V9.29801H40.064V6.90401H32V17.096Z" fill="black" fill-opacity="0.9" />
<path d="M41.7103 17.096H49.3823V14.702H44.4123V6.90401H41.7103V17.096Z" fill="black" fill-opacity="0.9" />
<path d="M55.4639 17.292C58.6139 17.292 60.9519 14.926 60.9519 12V11.972C60.9519 9.04601 58.6419 6.70801 55.4919 6.70801C52.3419 6.70801 50.0039 9.07401 50.0039 12V12.028C50.0039 14.954 52.3139 17.292 55.4639 17.292ZM55.4919 14.842C53.8819 14.842 52.7899 13.526 52.7899 12V11.972C52.7899 10.446 53.8679 9.15801 55.4639 9.15801C57.0739 9.15801 58.1659 10.474 58.1659 12V12.028C58.1659 13.554 57.1019 14.842 55.4919 14.842Z" fill="black" fill-opacity="0.9" />
<path d="M64.8997 17.166H67.2657L69.3517 11.146L71.4517 17.166H73.8177L77.2337 6.90401H74.4197L72.5857 13.008L70.5557 6.87601H68.2177L66.1877 13.008L64.3537 6.90401H61.4837L64.8997 17.166Z" fill="black" fill-opacity="0.9" />
<defs>
<linearGradient id="paint0_linear_1_101" x1="0" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse">
<stop stop-color="#2968B2" />
<stop offset="1" stop-color="#27AAE1" />
</linearGradient>
</defs>
</svg>
      )
    }
    