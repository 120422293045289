import { h } from 'preact';
import { CloseIcon } from './closeIcon';
import { ExternalLinkIcon } from './externalLinkIcon';
import { FocusTrap } from './focus-trap-preact';

export const Modal = ({ email = "email@domain.com", site = "Skills", personal = "A Cloud Guru", business = "Pluralsight", onClose}) => {
  return (
    <div class="modal">
        <div class="modalBackdrop" />
        <FocusTrap
          focusTrapOptions={{
            onDeactivate: onClose
          }}>
        <div role="dialog" aria-labelledby="dialogTitle" aria-describedby="dialogDesc" class="modalContainer">
          <div class="modalContent">
            <div class="modalContent--Close" tabIndex="0" aria-label="Close modal dialog" onClick={onClose}>
              <CloseIcon />
            </div>
            <div class="modalContent--Header">
              <h1 id="dialogTitle">Update your account to go to {site}</h1>
              <p id="dialogDesc">Your email address <strong>{email}</strong> is registered to both your <strong>personal {personal} account and your {business} employer/business-provided account.</strong></p>
            </div>
            <div class="modalContent--Panel">
              <div>
                <p>
                  In order to move between the two platforms, firstly visit the Integration Hub
                  and <a href="https://help.pluralsight.com/help/pluralsight-and-a-cloud-guru-dual-email-user-information" target="_blank" rel="noopener noreferrer">read this article</a> then
                  link your personal {personal} account ({email}) to your personal email address and keep your accounts separate.
                </p>
              </div>
              <a href="https://help.pluralsight.com/help/pluralsight-and-a-cloud-guru-dual-email-user-information" target="_blank" rel="noopener noreferrer" class="modalContent--Button__primary">
                Read update instructions
                <ExternalLinkIcon />
              </a>
            </div>
          </div>
        </div>
        </FocusTrap>
    </div>
  )
}
