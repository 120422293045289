import { h } from 'preact';

const GridIcon = () => (

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 3.6C3 3.03995 3 2.75992 3.10899 2.54601C3.20487 2.35785 3.35785 2.20487 3.54601 2.10899C3.75992 2 4.03995 2 4.6 2H6.4C6.96005 2 7.24008 2 7.45399 2.10899C7.64215 2.20487 7.79513 2.35785 7.89101 2.54601C8 2.75992 8 3.03995 8 3.6V5.4C8 5.96005 8 6.24008 7.89101 6.45399C7.79513 6.64215 7.64215 6.79513 7.45399 6.89101C7.24008 7 6.96005 7 6.4 7H4.6C4.03995 7 3.75992 7 3.54601 6.89101C3.35785 6.79513 3.20487 6.64215 3.10899 6.45399C3 6.24008 3 5.96005 3 5.4V3.6Z" fill="currentColor" />
<path d="M10 3.6C10 3.03995 10 2.75992 10.109 2.54601C10.2049 2.35785 10.3578 2.20487 10.546 2.10899C10.7599 2 11.0399 2 11.6 2H13.4C13.9601 2 14.2401 2 14.454 2.10899C14.6422 2.20487 14.7951 2.35785 14.891 2.54601C15 2.75992 15 3.03995 15 3.6V5.4C15 5.96005 15 6.24008 14.891 6.45399C14.7951 6.64215 14.6422 6.79513 14.454 6.89101C14.2401 7 13.9601 7 13.4 7H11.6C11.0399 7 10.7599 7 10.546 6.89101C10.3578 6.79513 10.2049 6.64215 10.109 6.45399C10 6.24008 10 5.96005 10 5.4V3.6Z" fill="currentColor" />
<path d="M17.109 2.54601C17 2.75992 17 3.03995 17 3.6V5.4C17 5.96005 17 6.24008 17.109 6.45399C17.2049 6.64215 17.3578 6.79513 17.546 6.89101C17.7599 7 18.0399 7 18.6 7H20.4C20.9601 7 21.2401 7 21.454 6.89101C21.6422 6.79513 21.7951 6.64215 21.891 6.45399C22 6.24008 22 5.96005 22 5.4V3.6C22 3.03995 22 2.75992 21.891 2.54601C21.7951 2.35785 21.6422 2.20487 21.454 2.10899C21.2401 2 20.9601 2 20.4 2H18.6C18.0399 2 17.7599 2 17.546 2.10899C17.3578 2.20487 17.2049 2.35785 17.109 2.54601Z" fill="currentColor" />
<path d="M3 10.6C3 10.0399 3 9.75992 3.10899 9.54601C3.20487 9.35785 3.35785 9.20487 3.54601 9.10899C3.75992 9 4.03995 9 4.6 9H6.4C6.96005 9 7.24008 9 7.45399 9.10899C7.64215 9.20487 7.79513 9.35785 7.89101 9.54601C8 9.75992 8 10.0399 8 10.6V12.4C8 12.9601 8 13.2401 7.89101 13.454C7.79513 13.6422 7.64215 13.7951 7.45399 13.891C7.24008 14 6.96005 14 6.4 14H4.6C4.03995 14 3.75992 14 3.54601 13.891C3.35785 13.7951 3.20487 13.6422 3.10899 13.454C3 13.2401 3 12.9601 3 12.4V10.6Z" fill="currentColor" />
<path d="M10.109 9.54601C10 9.75992 10 10.0399 10 10.6V12.4C10 12.9601 10 13.2401 10.109 13.454C10.2049 13.6422 10.3578 13.7951 10.546 13.891C10.7599 14 11.0399 14 11.6 14H13.4C13.9601 14 14.2401 14 14.454 13.891C14.6422 13.7951 14.7951 13.6422 14.891 13.454C15 13.2401 15 12.9601 15 12.4V10.6C15 10.0399 15 9.75992 14.891 9.54601C14.7951 9.35785 14.6422 9.20487 14.454 9.10899C14.2401 9 13.9601 9 13.4 9H11.6C11.0399 9 10.7599 9 10.546 9.10899C10.3578 9.20487 10.2049 9.35785 10.109 9.54601Z" fill="currentColor" />
<path d="M17 10.6C17 10.0399 17 9.75992 17.109 9.54601C17.2049 9.35785 17.3578 9.20487 17.546 9.10899C17.7599 9 18.0399 9 18.6 9H20.4C20.9601 9 21.2401 9 21.454 9.10899C21.6422 9.20487 21.7951 9.35785 21.891 9.54601C22 9.75992 22 10.0399 22 10.6V12.4C22 12.9601 22 13.2401 21.891 13.454C21.7951 13.6422 21.6422 13.7951 21.454 13.891C21.2401 14 20.9601 14 20.4 14H18.6C18.0399 14 17.7599 14 17.546 13.891C17.3578 13.7951 17.2049 13.6422 17.109 13.454C17 13.2401 17 12.9601 17 12.4V10.6Z" fill="currentColor" />
<path d="M3.10899 16.546C3 16.7599 3 17.0399 3 17.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H6.4C6.96005 21 7.24008 21 7.45399 20.891C7.64215 20.7951 7.79513 20.6422 7.89101 20.454C8 20.2401 8 19.9601 8 19.4V17.6C8 17.0399 8 16.7599 7.89101 16.546C7.79513 16.3578 7.64215 16.2049 7.45399 16.109C7.24008 16 6.96005 16 6.4 16H4.6C4.03995 16 3.75992 16 3.54601 16.109C3.35785 16.2049 3.20487 16.3578 3.10899 16.546Z" fill="currentColor" />
<path d="M10 17.6C10 17.0399 10 16.7599 10.109 16.546C10.2049 16.3578 10.3578 16.2049 10.546 16.109C10.7599 16 11.0399 16 11.6 16H13.4C13.9601 16 14.2401 16 14.454 16.109C14.6422 16.2049 14.7951 16.3578 14.891 16.546C15 16.7599 15 17.0399 15 17.6V19.4C15 19.9601 15 20.2401 14.891 20.454C14.7951 20.6422 14.6422 20.7951 14.454 20.891C14.2401 21 13.9601 21 13.4 21H11.6C11.0399 21 10.7599 21 10.546 20.891C10.3578 20.7951 10.2049 20.6422 10.109 20.454C10 20.2401 10 19.9601 10 19.4V17.6Z" fill="currentColor" />
<path d="M17.109 16.546C17 16.7599 17 17.0399 17 17.6V19.4C17 19.9601 17 20.2401 17.109 20.454C17.2049 20.6422 17.3578 20.7951 17.546 20.891C17.7599 21 18.0399 21 18.6 21H20.4C20.9601 21 21.2401 21 21.454 20.891C21.6422 20.7951 21.7951 20.6422 21.891 20.454C22 20.2401 22 19.9601 22 19.4V17.6C22 17.0399 22 16.7599 21.891 16.546C21.7951 16.3578 21.6422 16.2049 21.454 16.109C21.2401 16 20.9601 16 20.4 16H18.6C18.0399 16 17.7599 16 17.546 16.109C17.3578 16.2049 17.2049 16.3578 17.109 16.546Z" fill="currentColor" />
</svg>

);

export default GridIcon;